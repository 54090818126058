import React, { useRef, useState } from "react";
import pbimage from "../logos/elon.png";
import pbimage2 from "../logos/img2.png";

import facebook from "../logos/facebook.png";
import instagram from "../logos/instagram.png";
import linkedin from "../logos/linkedin.png";
import youtube from "../logos/youtube.png";
import twitter from "../logos/twitter.png";

import whatsapp from "../logos/whatsapp.png";
import weblab from "../logos/weblab logo.png";
import result1 from "../logos/result1.png";
import result2 from "../logos/result2.png";
import result3 from "../logos/result3.png";
import result4 from "../logos/result4.png";
import result5 from "../logos/result5.png";

import logo from "../logos/zinfluence white.png";

import Ham from "./NavBarHam";

import middlevid from "../videos/middle vid.mp4";
import leftvid from "../videos/left vid.mp4";
import rightvid from "../videos/right vid.mp4";
import Countup from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import founder from "../logos/founder.png";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const Home = () => {
  const [counterOn, setCounterOn] = useState(false);
  const home = useRef();
  const about = useRef();
  const service = useRef();
  const contact = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const scrollHandler = (useref) => {
    console.log(useref.current.offsetTop);
    window.scrollTo({ top: useref.current.offsetTop, behavior: "smooth" });
  };
  return (
    <>
      <a
        href="https://wa.me/message/36UHMZAE3U2EP1"
        target="_blank"
        id="whatsapp"
      >
        <img src={whatsapp}></img>
      </a>
      <div className="navbar">
        <div className="navbarlogo">
          <a href="#home">
            <img src={logo}></img>
          </a>
        </div>
        <div className="navbaritems">
          <div className="navbaritem">
            <a
              onClick={() => {
                scrollHandler(home);
              }}
            >
              Home
            </a>
          </div>
          <div className="navbaritem">
            <a
              onClick={() => {
                scrollHandler(service);
              }}
            >
              Service
            </a>
          </div>
          <div className="navbaritem">
            <a
              onClick={() => {
                scrollHandler(about);
              }}
            >
              About
            </a>
          </div>

          <div className="navbaritem">
            <a
              href="#contact"
              style={{ color: "white", textDecoration: "none" }}
            >
              Contact
            </a>
          </div>
        </div>
        <Ham></Ham>
      </div>
      <div className="Home" id="home" ref={home}>
        <div className="HomeContainer">
          <div className="HomeContainerDiv1">
            <div>
              Turning Individuals Into
              <span className="HomeContainerDiv1img">An Influence</span>
            </div>
          </div>
          <div className="HomeContainerDiv3">
            We help founders and business owners establish a strong authority by
            growing their personal brand
          </div>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe9E2R7fyvFIy1TtWIGgErjXB8h_byQADaZMKvn20ezOTkDGQ/viewform?embedded=true"
            className="HomeContainerDiv2"
            target="_blank"
          >
            {" "}
            Get in Touch
          </a>

          <div className="pbimageContainer" data-aos="fade-up">
            <img className="pbimage" src={pbimage}></img>
            <div className="pbimageText">
              'In todays world people trust people more than brands'
            </div>
          </div>
        </div>
      </div>
      <div className="page2">
        <div className="page2Container">
          <div className="page2Heading1" data-aos="zoom-in">
            What's The Best Way To Reach Audience ?
          </div>
          <img
            className=" page2logo"
            data-aos="fade-right"
            src={pbimage2}
          ></img>
          <div className="page2Heading2">
            <div data-aos="fade-up">
              Through A Strong
              <span className="page2Heading2pb" data-aos="fade-up">
                Personal Brand
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="page3">
        <div className="page3Container">
          <div className="page3Heading1" data-aos="zoom-out">
            Personal Branding Helps You
          </div>

          <div className="page3Heading2">
            <div className="page3Heading2item" data-aos="zoom-in">
              <div className="page3Heading2itemhead">Build trust</div>
              <div>
                It helps you connect with audience and make people trust you
              </div>
            </div>
            <div className="page3Heading2item" data-aos="zoom-in">
              <div className="page3Heading2itemhead">Position As a Leader</div>
              <div>Establish yourself as a leader in your industry</div>
            </div>
            <div className="page3Heading2item" data-aos="zoom-in">
              <div className="page3Heading2itemhead">Grow Your Business</div>
              <div>
                Whether it is to sell a product or service or to generate leads.
                you can be able to do
              </div>
            </div>
          </div>
        </div>
        <div className="whatweofferContainer" ref={service} id="service">
          <div className="whatweofferContaineritem1" data-aos="zoom-out">
            What We <span className="underlineoffer"> Offer ?</span>
          </div>

          <div className="whatweofferContaineritem3" data-aos="zoom-out">
            <div>
              <a href="#pboninsta">Personal Branding On INSTAGRAM </a>
            </div>
            <div>
              <a href="#pbonlinked">Personal Branding On LINKEDIN</a>
            </div>
          </div>
        </div>
      </div>
      <div className="page4" id="pboninsta">
        <div className="page4Socialbar" data-aos="fade-right">
          Personal Branding On Instagram
        </div>
        <div className="page4item1" data-aos="fade-right">
          Your views are not just views, They are your
        </div>
        <span className="page4item2">Potential Customers.</span>

        <div className="page4item3" data-aos="fade-right">
          <video
            src={rightvid}
            loop
            autoPlay
            muted
            playsInline
            preload="auto"
          />
          <video src={leftvid} loop autoPlay muted playsInline preload="auto" />
          <video
            src={middlevid}
            loop
            autoPlay
            muted
            playsInline
            preload="auto"
          />
        </div>
        <div className="page4item4" data-aos="zoom-in">
          Distribute Your Short Form Content Across
          <span className="underline" data-aos="zoom-in">
            {" "}
            Multiple Platforms
          </span>
        </div>
        <div className="page4item5ScrollBar" data-aos="zoom-out">
          <div class="logos-slide">
            <div> Instagram</div>
            <div>facebook</div>
            <div>Youtube</div>
            <div> Instagram</div>
            <div>facebook</div>
            <div>Youtube</div>
            <div> Instagram</div>
            <div>facebook</div>
            <div>Youtube</div>
            <div> Instagram</div>
            <div>facebook</div>
            <div>Youtube</div>
            <div> Instagram</div>
            <div>facebook</div>
            <div>Youtube</div>
            <div> Instagram</div>
            <div>facebook</div>
            <div>Youtube</div>
            <div> Instagram</div>
            <div>facebook</div>
            <div>Youtube</div>
            <div> Instagram</div>
            <div>facebook</div>
            <div>linkedin</div>
          </div>
        </div>
        <div className="page4item6" data-aos="zoom-in">
          We are here to help you shoot ,create ,edit and produce short form
          content to reach your targeted audience.
        </div>
      </div>
      <ScrollTrigger
        onEnter={() => {
          setCounterOn(true);
        }}
        onExit={() => {
          setCounterOn(false);
        }}
      >
        <div className="page5">
          <div className="page5container" id="pbonlinked">
            <div className="page5item1" data-aos="fade-left">
              Personal Branding On LinkedIn
            </div>
            <div className="page5item2" data-aos="fade-left">
              LinkedIn is a goldmine when it comes to lead generation,networking
              and <span className="underline1"> buliding authority</span>
            </div>

            <div className="page5impressionbar" data-aos="fade-left">
              <div className="page5impressionbaritem">
                <div>
                  {counterOn && (
                    <Countup start={5000} end={408760} duration={8} delay={0} />
                  )}
                  +
                </div>
                <div>Impression</div>
              </div>
              <div className="page5impressionbaritem">
                <div>
                  {counterOn && (
                    <Countup start={5000} end={77895} duration={8} delay={0} />
                  )}
                  +
                </div>
                <div>Unique Veiws</div>
              </div>
              <div className="page5impressionbaritem">
                <div>
                  {counterOn && (
                    <Countup start={5000} end={18582} duration={8} delay={0} />
                  )}
                  +
                </div>
                <div>Engagements</div>
              </div>
            </div>
            <div className="page5item2" data-aos="fade-left">
              LinkedIn is a effective platform over 130 million users in India.
            </div>
          </div>
          <div className="page5item3">
            <div className="page5item3item" data-aos="zoom-in">
              <div className="page3Heading2itemhead">Content</div>
              <div>We create content that aligns with your audience.</div>
            </div>
            <div className="page5item3item" data-aos="zoom-in">
              <div className="page3Heading2itemhead">Connection</div>
              <div>
                We help you build geniune connection that can turn into
                business.
              </div>
            </div>
            <div className="page5item3item" data-aos="zoom-in">
              <div className="page3Heading2itemhead">Followers</div>
              <div>Increase your followers rate by 4x.</div>
            </div>
            <div className="page5item3item" data-aos="zoom-in">
              <div className="page3Heading2itemhead">Lead generation</div>
              <div>Inboard or outhand increase your leads by 3x.</div>
            </div>
          </div>
        </div>
      </ScrollTrigger>
      <div className="page6">
        <div className="page6heading" data-aos="fade-right">
          How We <span className="underlinetl">do It ?</span>
        </div>
        <div className="page6timeline">
          <div className="page6timelinecontainer">
            <div className="page6timelineboxcontainer" data-aos="fade-left">
              <div className="page6timelinebox">
                <div className="page3Heading2itemhead"> 01-Research</div>
                <div>
                  we start by doing a market research about your industry & your
                  competitors
                </div>
              </div>
            </div>
            <div className="page6timelineboxcontainer" data-aos="fade-left">
              <div className="page6timelinebox">
                <div className="page3Heading2itemhead">02-Optimization</div>
                <div>
                  we Optimize your profile whether its LinkedIn /Instagram
                  /Youtube /Facebook to make you standout in searches
                </div>
              </div>
            </div>
            <div className="page6timelineboxcontainer" data-aos="fade-left">
              <div className="page6timelinebox">
                <div className="page3Heading2itemhead">03-Content Plan</div>
                <div>
                  whether its text content / video content we analyse & create a
                  plan that will effectively reach you to your target audience
                </div>
              </div>
            </div>
            <div className="page6timelineboxcontainer" data-aos="fade-left">
              <div className="page6timelinebox">
                <div className="page3Heading2itemhead">
                  04-Content Production
                </div>
                <div>
                  our team will shoot, produce,edit and do corrections of every
                  format of content (videos,posts,carousels)
                </div>
              </div>
            </div>
            <div className="page6timelineboxcontainer" data-aos="fade-left">
              <div className="page6timelinebox">
                <div className="page3Heading2itemhead">05-Data Analysis</div>
                <div>
                  we as a team analyze the data of the content once after
                  posting it,we analyze every metrics
                </div>
              </div>
            </div>
            <div className="page6timelineboxcontainer" data-aos="fade-left">
              <div className="page6timelinebox">
                <div className="page3Heading2itemhead">06-Progress Report</div>
                <div>
                  we as a team conduct a internal meeting & whats not working &
                  revise our strategies
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page65">
        <div className="page5item1">Our Results</div>
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img className="page65img" src={result1} />
            </div>
            <div>
              <img className="page65img" src={result2} />
            </div>
            <div>
              <img className="page65img" src={result3} />
            </div>
            <div>
              <img className="page65img" src={result4} />
            </div>
            <div>
              <img className="page65img" src={result5} />
            </div>
          </Slider>
        </div>
      </div>

      <div className="page7">
        <div className="page7ScrollBar" data-aos="zoom-out">
          <div class="page7ScrollBar-slide">
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                The Team has a good understanding of LinkedIn. They know all
                nooks and corners, so they really help to elevate you as per
                your need. I'm very much impressed about their service.
              </div>
              <div class="page7ScrollBaritemName">
                <div>Renga Praveen Kumar</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                The Zinfluence team did an amazing job helping me grow my
                aquarium page and personal brand on Instagram! They really
                understood what I wanted and created content that connected with
                my followers. My page grew fast, and I got a lot of great
                feedback. If you want to boost your brand, I highly recommend
                them!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Naveen Seastar</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                I worked with Zinfluence, and it was a great experience. They
                helped me design my profile, and everything went smoothly. If
                you want someone who is professional and simple to work with, I
                highly recommend Zinfluence!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Hardik Godha</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                Nishaanth and his team is very insightful. They are very much
                updated on the industry trends. They know their game, are
                passionate, and on top of that, they really have keen eye to
                details! Highly recommended!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Nimrah Fatima</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                I had an amazing conversation with Zinfluence. I was impressed
                by their thoughts and advice on Personal Branding. They are very
                passionate and have an eye for small details. Highly recommend!!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Ananya Raj</div>
              </div>
            </div>

            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                The Team has a good understanding of LinkedIn. They know all
                nooks and corners, so they really help to elevate you as per
                your need. I'm very much impressed about their service.
              </div>
              <div class="page7ScrollBaritemName">
                <div>Renga Praveen Kumar</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                The Zinfluence team did an amazing job helping me grow my
                aquarium page and personal brand on Instagram! They really
                understood what I wanted and created content that connected with
                my followers. My page grew fast, and I got a lot of great
                feedback. I highly recommend them!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Naveen Seastar</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                I worked with Zinfluence, and it was a great experience. They
                helped me design my profile, and everything went smoothly. They
                keep it calm and are easy to work with, and listens carefully to
                what you need. If you want someone who is professional and
                simple to work with, I highly recommend Zinfluence!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Hardik Godha</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                Nishaanth and his team is very insightful. Their knowledge and
                advice on Personal Branding is a must to look forward to. They
                know their game, are passionate, and on top of that, they really
                have keen eye to details! Highly recommended!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Nimrah Fatima</div>
              </div>
            </div>
            <div class="page7ScrollBaritem">
              <div class="page7ScrollBaritemDiscr">
                I had an amazing conversation with Zinfluence. I was impressed
                by their thoughts and advice on Personal Branding. They are very
                passionate and have an eye for small details. Highly recommend!!
              </div>
              <div class="page7ScrollBaritemName">
                <div>Ananya Raj</div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe9E2R7fyvFIy1TtWIGgErjXB8h_byQADaZMKvn20ezOTkDGQ/viewform?embedded=true"
          className="HomeContainerDiv2"
          target="_blank"
        >
          {" "}
          Get in Touch
        </a>
      </div>
      <div className="page8" id="about" ref={about}>
        <div className="page4Socialbar" data-aos="fade-left">
          About
        </div>
        <div className="page4item1" data-aos="fade-left">
          we as a team have only one goal in mind, that is to help you and your
          business grow on the platform
        </div>
        <div className="page8founderbox" data-aos="zoom-out">
          <div className="page8founderboxheading" data-aos="fade-left">
            Meet The Founder
          </div>
          <div className="page8founderboxitems" data-aos="fade-left">
            <div className="page8founderboxitem1">
              <div className="page8founderboxitem1div1">
                <span className="underline9">Nishaanth Narayan</span> - an
                entrepreneur with a bold vision is passionate about taking
                personal branding to the next level. As a content creator, he
                believes content is the next big revolution that will transform
                businesses and the world.
              </div>
              <div className="page8founderboxitem1div1">
                Driven by the belief that people trust people more than brands,
                Nishaanth founded this agency to help individuals build genuine
                trust and authority. His mission is to empower entrepreneurs and
                professionals to amplify their personal brands and unlock
                their full potential.
              </div>

              <div className="page8founderboxitem1div2">
                <div className="page8founderboxitem1div2div1">
                  Follow Nishanth On Socials
                </div>
                <div className="page8founderboxitem1div2imgs">
                  <a
                    href="https://www.instagram.com/nishaanthnarayan/"
                    target="_blank"
                  >
                    <img src={instagram}></img>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/zinfluence-in"
                    target="_blank"
                  >
                    <img src={linkedin}></img>
                  </a>
                  <a
                    href="https://www.facebook.com/nishaanthnarayan/"
                    target="_blank"
                  >
                    <img src={facebook}></img>
                  </a>
                  <a
                    href="https://www.youtube.com/@NishaanthNarayan
"
                    target="_blank"
                  >
                    <img src={youtube}></img>
                  </a>

                  <a href="https://x.com/nishaanth_19" target="_blank">
                    <img id="twitter" src={twitter}></img>
                  </a>
                </div>
              </div>
            </div>

            <div className="page8founderboxitem2" data-aos="fade-right">
              <img src={founder} />
            </div>
          </div>
        </div>
      </div>
      <div className="page9" id="contact" ref={contact}>
        <div className="page4Socialbar" data-aos="fade-right">
          Contact Us
        </div>
        <div className="page4item1" data-aos="fade-right">
          You are just one click away from becoming an internet sensation and
          taking your business to the{" "}
          <span className="underline1">next level.</span>
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe9E2R7fyvFIy1TtWIGgErjXB8h_byQADaZMKvn20ezOTkDGQ/viewform?embedded=true"
          className="HomeContainerDiv2"
          target="_blank"
        >
          {" "}
          Get in Touch
        </a>
      </div>
      <div className="page10" id="footer" ref={contact}>
        <div className="footerlogo">
          {" "}
          <img src={logo}></img>
        </div>
        <div className="footerlinks">
          <h2>Navigate</h2>

          <a
            onClick={() => {
              scrollHandler(home);
            }}
          >
            Home
          </a>

          <a
            onClick={() => {
              scrollHandler(service);
            }}
          >
            Service
          </a>

          <a
            onClick={() => {
              scrollHandler(about);
            }}
          >
            About
          </a>

          <a href="#contact" style={{ color: "white", textDecoration: "none" }}>
            Contact
          </a>
        </div>
        <div className="footerScociallinks">
          <h2>Socials</h2>
          <a
            href="https://www.linkedin.com/company/zinfluence-in"
            target="_blank"
          >
            LinkedIn
          </a>
          <a href="https://www.instagram.com/zinfluence.in/" target="_blank">
            Instagram
          </a>
          <a
            href="https://www.linkedin.com/company/zinfluence-in"
            target="_blank"
          >
            Facebook
          </a>
          <a
            href="https://www.linkedin.com/company/zinfluence-in"
            target="_blank"
            style={{ color: "black" }}
          >
            Twitter
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe9E2R7fyvFIy1TtWIGgErjXB8h_byQADaZMKvn20ezOTkDGQ/viewform?embedded=true"
            className="HomeContainerDiv2 footergetintouch"
            target="_blank"
          >
            {" "}
            Get in Touch
          </a>
          <div style={{ fontSize: "14px", padding: "0px" }}>
            Contact@zinfluence.in
          </div>
        </div>
      </div>
      <div className="final">
        <div className="finalcontainer">
          <div className="copyright">
            © 2024 Zinfluence. All Rights Reserved.
          </div>
          <div className="credits">
            <div>Developed By </div>

            <a className="weblablogo">
              <img src={weblab}></img>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
