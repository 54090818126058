import React, { useState } from "react";
import ham from "../logos/hamburger.png";
import "./NavBarHam.css";
import "./Home.css";
const NavBarHam = () => {
  const [hamClick, setHamClick] = useState(false);
  const hamHandler = () => {
    setHamClick((prev) => {
      return !prev;
    });
  };
  return (
    <>
      <div className="hamimg">
        <img onClick={hamHandler} src={ham}></img>
        {hamClick && (
          <div className="hamimgitems">
            <a href="#home">Home</a>
            <a href="#service">Service</a>
            <a href="#about">About</a>
            <a href="#contact">Contact</a>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBarHam;
